import React from 'react';
import PropTypes from 'prop-types';
import { getUrlParam } from '@helpers';
import {
  Container,
  Hero,
  ResetPasswordForm,
  Box,
} from '@components';
import MESSAGES from '@messages';

const getToken = ({ location }) => getUrlParam('token', location);
const getEmail = ({ location }) => getUrlParam('email', location);

const ResetPasswordPage = (props) => (
  <Container textAlign="center">
    <Hero
      title={MESSAGES.SET_PASSWORD_TITLE}
      center
      seo={{
        metaTitle: MESSAGES.SET_PASSWORD_META_TITLE,
      }}
    />
    <Box width={[1, 2 / 3]} mx="auto">
      <ResetPasswordForm token={getToken(props)} email={getEmail(props)}/>
    </Box>
  </Container>
);
ResetPasswordPage.propTypes = {
  location: PropTypes.shape({}),
};

export default ResetPasswordPage;
